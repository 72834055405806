export default {
  props: {
    question: { type: Object, required: true }
  },
  methods: {
    propSet(field, value) {
      this.$emit('change', { ...this.question, [field]: value });
    }
  }
};
